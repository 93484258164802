#footer_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    background-color: #212529;
    color: #fff;
}

#footer_container a {
    color: #fff;
    text-decoration: none;
}

#footer_container a:hover {
    color: #fff;
    text-decoration: underline;
}

#footer_bottom {
    margin-top: 5%;
}

.crest_footer {
    width: 50px;
    height: auto;
    display: block;
    margin: 5% 10%;
}

#footer_logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#social_media_icons {
    display: flex;
    flex-direction: row;
}

#social_media_icons li {
    margin: 0 5px;
}

#ig_icon {
    width: 50px;
    height: auto;
}

#linkedin_icon {
    width: 50px;
    height: auto;
}

@media screen and (max-width: 600px) {

    /* #footer_container {
        display: grid;
        grid-template-columns: (2, 1fr);
    } */

   #footer_container h4 {
        font-size: 0.9em;
    }

    .footer_section {
        margin: 10px auto;
        text-align: center;
    }

    #footer_container li {
        font-size: 0.7em;
    }

    #social_media_icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #ig_icon {
        width: 40px;
        height: auto;
    }

    #linkedin_icon {
        width: 40px;
        height: auto;
    }

    .crest_footer {
        width: 40px;
        height: auto;
        display: flex;
        justify-content: center;
        margin: 0 5%;
    }

    #footer_logo_container {
        justify-content: center;
    }
    

    #footer_bottom p {
        font-size: 0.65em;
    }
}