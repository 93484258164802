#db {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;;
}

/* position the #link button in the bottom right corner of the page */
#link {
    position: absolute;
    bottom: 80px;
    right: 80px;
}

#link a:hover {
    cursor: pointer;
}
