#carousel_container {
    margin: 0 15%;
    padding: 5% 0;
}

#outer_container {
    width: 100%;
    height: 120vh;
    background-color: #ffffff;
}


@media screen and (max-width: 600px) {
    #carousel_container {
        margin: 0;
    }
}