#header_container {
    display: flex;
    flex-direction: row;
    margin: 20px 50px;
    padding-right: 50px;
}

#header_logo {
    width: 50px;
    height: auto;
}

#header_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #fff;
}

#header_title h1 {
    font-size: 1.5em;
}

#header_title h2 {
    font-size: 1.2em;
}

@media screen and (max-width: 600px) {
    #header_container {
        margin: 10px 5px;
        padding-right: 0;
    }

    #header_title h1 {
        font-size: 1.2em;
    }

    #header_title h2 {
        font-size: 0.8em;
    }
}