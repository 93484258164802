 .gallery_image {
    max-width: 500px;
    height: auto;
    margin: 5% 5%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

#gallery-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* max-width: /; */
}

@media screen and (max-width: 780px) {
    .gallery_image {
        max-width: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}