.Settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
    margin: 0 auto;
    max-width: 480px;
}

.Settings>.LoaderButton {
    margin: 3%;
}