#donations_card_container {
    display: flex;
    flex-direction: column;
    margin: 5% 15%;
}

#donations_form {
    display: flex;
    flex-direction: column;
    margin: 3% auto;
    width: 175px;
}

#donations_card_container h1, p {
    text-align: center;
}