.ChangeEmail {
    height: 30vh;
    margin: 5%;
}

#confirm {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 480px;
}

/* .ChangeEmail div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
} */

@media all and (min-width: 480px) {
    .ChangeEmail {
      padding: 60px 0;
    }
  
    .ChangeEmail form {
      margin: 0 auto;
      max-width: 320px;
    }
  }