#history_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#history_container p {
    font-size: 1.2rem;
    font-weight: 250;
    margin: 1.3% 0;
}

#top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
}

#top img {
    margin-bottom: 2%;
}

#top h1 {
    margin-top: 2%;
}


#buildings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#buildings div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#buildings img {
    max-width: 800px;
    height: auto;
    margin: 2% 5%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

#cobb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
}

#cobb img {
    max-width: 1000px;
    height: auto;
    margin: 2% 5%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 600px) {
    #buildings img {
        max-width: 300px;
    }

    #cobb img {
        max-width: 300px;
    }

    #top img {
        max-width: 350px;
        height: auto;
        margin: 2% 5%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    }
}