#exec_card_container {
    /* width: 18rem; */
    margin: 0 1%;
}

.exec_card_img {
    max-width: 100%;
    max-height: auto;
    object-fit: contain;
}

@media  screen and (max-width: 780px) {
    #exec_card_container {
        width: 80%;
        margin: 0 10%;
        margin-top: 5%;
    }

    .exec_card_title {
        font-size: 1rem;
    }
}