#calendar_container_desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}

#calendar_container_mobile {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
}

@media screen and (max-width: 800px) {
    #calendar_container_desktop {
        display: none;
    }

    #calendar_container_mobile {
        display: flex;
        margin: 2%;
    }
}



