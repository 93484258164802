.connect_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% 15%;
}

.button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 60px 0;
    margin: 100px auto;
    max-width: 480px;
}