#paypal_container {
    padding: 6% 0;
    text-align: center;
}

#selectDues {
    margin: 0 auto;
    max-width: 500px;
}

@media screen and (max-width: 600px) {
    #paypal_container {
        padding: 3%;
    }
}