#card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 5% 5%;
}

#mobile_left_container {
    display: flex;
    flex-direction: row;
}

#mobile_right_container {
    display: flex;
    flex-direction: row;
}

#exec_container h1 {
   text-align: center;
   margin-top: 5%;
   margin-bottom: 0;
}

@media screen and (max-width: 780px) {
    #mobile_left_container {
        flex-direction: column;
    }
    #mobile_right_container {
        flex-direction: column;
    }

    #card_container {
        margin: 0;
    }

    #exec_container h1 {
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 1.3rem;
    }
}